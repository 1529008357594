<template>
  <div style="height: 100%">
    <div class="scroll" v-if="isAccess">
      <div class="scroll-top">
        <div class="head" :style="{ position: headpos }">
          <Tabs :tabs="tabs" :fnClickTabs="fnClickTabs" :currentIndex="currentIndex" style="width: 3.5rem"></Tabs>
          <!-- 搜索 -->
          <div class="search">
            <router-link :to="{ path: '/search' }">
              <div class="search-box">
                <span class="icon"></span>
                搜索
              </div>
            </router-link>
          </div>
          <!-- /搜索 -->
        </div>
        <!-- 更新时间 -->
        <div class="time">
          <span>{{ time }}</span>
        </div>
        <!-- 更新时间 -->
      </div>
      <div class="scroll-center">
        <!-- 汇总数据 -->
        <div class="alldata">
          <div class="alldata-item" v-for="(item, index) in allDataList" :key="index">
            <router-link :to="{ name: 'AllDetails', query: { game_type: item.game_type, time_type: time_type } }">
              <h2>
                {{ game_type_name[item.game_type] }}
              </h2>
              <p class="s">
                {{ item.game_type === 5 ? '$' : '¥' }}{{ item.pay | million | fillZero | priceInit
                }}<span v-show="item.pay > 9999">万</span>
              </p>
              <p class="b">
                <span>环比:</span>
                <span class="n" v-if="!item.relative_ratio">-</span>
                <span :class="[item.relative_ratio > 0 ? 'c' : 'j']" v-else>
                  <template v-if="item.relative_ratio > 0">+</template>{{ item.relative_ratio }}%
                </span>
              </p>
            </router-link>
          </div>
        </div>
        <!-- 汇总数据 -->
        <!-- 产品tabs -->
        <div class="product-tabs">
          <ul>
            <li @click="fnProductTabs(0)" :class="[!productActive ? 'active' : '']">
              <span class="icon1"></span>我的关注
            </li>
            <li @click="fnProductTabs(1)" :class="[productActive ? 'active' : '']">
              <span class="icon2"></span>全部产品
            </li>
          </ul>
        </div>
        <!-- /产品tabs -->
        <!-- 产品列表 -->
        <div class="product-list" v-if="!productActive">
          <div class="product-list-box" v-if="productList.length">
            <div class="product-list-box-top">
              <span class="t1">
                <router-link :to="{ name: 'GuanZhuEdit', query: { list: productList } }"
                  >编辑<em class="icon1"></em>
                </router-link>
              </span>
              <span class="t2" @click="fnClickListTabs(0, 'pay')">
                总收入
                <em :class="[listTabsIndex === 0 ? isStatus : '']"> </em>
              </span>
              <span class="t3" @click="fnClickListTabs(1, 'complete')"
                >完成度
                <em :class="[listTabsIndex === 1 ? isStatus : '']"> </em>
              </span>
              <span class="t4" @click="fnClickListTabs(2, 'relative_ratio')"
                >环比
                <em :class="[listTabsIndex === 2 ? isStatus : '']"> </em>
              </span>
            </div>
            <div class="product-list-box-cent">
              <div class="product-list-box-cent-item" v-for="(item, index) in productList" :key="index">
                <router-link :to="{ name: 'Details', query: { game_id: item.game_id, game_name: item.game_name } }">
                </router-link>
                <span class="t1">
                  <!-- <h2 :style="{ 'font-size': item.game_name.length > 7 ? '12px' : '' }">{{ item.game_name }}</h2> -->
                  <h2>{{ item.game_name }}</h2>
                  <p>
                    <b>{{ game_type_name[item.game_type] }}</b>
                  </p>
                </span>
                <span
                  class="t2"
                  :class="[
                    time_progress !== item.complete && item.complete ? (time_progress > item.complete ? 'r' : 'h') : ''
                  ]"
                >
                  {{ item.conf.symbol }}{{ item.pay | million | fillZero | priceInit
                  }}<template v-if="item.pay > 9999">万</template>
                </span>
                <span
                  class="t3"
                  :class="[
                    time_progress !== item.complete && item.complete ? (time_progress > item.complete ? 'r' : 'h') : ''
                  ]"
                >
                  <template v-if="item.complete">
                    {{ item.complete > 0 ? (item.complete >= 999 ? '>' : '') : ''
                    }}{{ item.complete >= 999 ? '999.00' : item.complete }}%
                  </template>
                  <template v-else>-</template>
                </span>
                <span
                  class="t4"
                  :style="{ 'font-size': (item.relative_ratio && item.relative_ratio.length) >= 7 ? '10px' : '' }"
                  :class="[
                    item.relative_ratio !== 0 && item.relative_ratio ? (item.relative_ratio < 0 ? 'r' : 'h') : ''
                  ]"
                >
                  <template v-if="item.relative_ratio">
                    {{ item.relative_ratio > 0 ? (item.relative_ratio >= 9999 ? '>' : '+') : ''
                    }}{{ item.relative_ratio >= 9999 ? '9999.00' : item.relative_ratio }}%
                  </template>
                  <template v-else>-</template>
                </span>
              </div>
            </div>
            <router-link :to="{ path: '/search' }">
              <div class="product-list-add">新增关注<em></em></div>
            </router-link>
          </div>
          <div class="product-list-no" v-else>
            <em></em>
            <p>您还未关注任何产品～</p>
            <router-link :to="{ path: '/search' }"> 去关注 </router-link>
          </div>
        </div>
        <div class="product-list" v-else>
          <div class="product-list-box" v-if="allProductList.length">
            <div class="product-list-box-top">
              <span class="t1"></span>
              <span class="t2" @click="fnClickListTabs(0, 'pay')">
                总收入
                <em :class="[listTabsIndex === 0 ? isStatus : '']"> </em>
              </span>
              <span class="t3" @click="fnClickListTabs(1, 'complete')">
                完成度<em :class="[listTabsIndex === 1 ? isStatus : '']"></em>
              </span>
              <span class="t4" @click="fnClickListTabs(2, 'relative_ratio')">
                环比<em :class="[listTabsIndex === 2 ? isStatus : '']"></em>
              </span>
            </div>
            <div class="product-list-box-cent">
              <div class="product-list-box-cent-item" v-for="(item, index) in allProductList" :key="index">
                <router-link
                  class="a1"
                  :to="{ path: '/details', query: { game_id: item.game_id, game_name: item.game_name } }"
                >
                </router-link>
                <span class="t0" :class="[item.concern ? 'y' : '']" @click.stop="fnClickGuanzhu(item)"><em></em></span>
                <span class="t1 a">
                  <!-- <h2 :style="{ 'font-size': item.game_name.length > 7 ? '12px' : '' }">{{ item.game_name }}</h2> -->
                  <h2>{{ item.game_name }}</h2>
                  <p>
                    <b>{{ game_type_name[item.game_type] }}</b>
                  </p>
                </span>
                <span
                  class="t2"
                  :class="[
                    time_progress !== item.complete && item.complete ? (time_progress > item.complete ? 'r' : 'h') : ''
                  ]"
                >
                  {{ item.conf.symbol }}{{ item.pay | million | fillZero | priceInit
                  }}<template v-if="item.pay > 9999">万</template>
                </span>
                <span
                  class="t3"
                  :class="[
                    time_progress !== item.complete && item.complete ? (time_progress > item.complete ? 'r' : 'h') : ''
                  ]"
                >
                  <template v-if="item.complete">
                    {{ item.complete > 0 ? (item.complete >= 999 ? '>' : '') : ''
                    }}{{ item.complete >= 999 ? '999.00' : item.complete }}%
                  </template>
                  <template v-else>-</template>
                </span>
                <span
                  class="t4"
                  :style="{ 'font-size': (item.relative_ratio && item.relative_ratio.length) >= 7 ? '10px' : '' }"
                  :class="[
                    item.relative_ratio !== 0 && item.relative_ratio ? (item.relative_ratio < 0 ? 'r' : 'h') : ''
                  ]"
                >
                  <template v-if="item.relative_ratio">
                    {{ item.relative_ratio > 0 ? (item.relative_ratio >= 9999 ? '>' : '+') : ''
                    }}{{ item.relative_ratio >= 9999 ? '9999.00' : item.relative_ratio }}%
                  </template>
                  <template v-else>-</template>
                </span>
              </div>
            </div>
          </div>
          <div class="product-list-no" v-else>
            <em></em>
            <p>暂无任何产品</p>
          </div>
        </div>
        <!-- /产品列表 -->
      </div>
      <div class="scroll-footer">
        <!-- 底部导航 -->
        <FooterNav></FooterNav>
      </div>
    </div>
    <div class="page-no" v-else>
      <van-icon name="user-o" size="70" color="#999" />
      <p>您还没有权限</p>
    </div>
    <van-overlay :show="showLoading">
      <div class="vant-loading">
        <van-loading />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { FooterNav, Tabs } from '@/components'
import { dataGameoverView, dataInit, dataOverView } from '@/api/home'
import { fnGameFocus, fnGameUnfocus } from '@/common/common'
export default {
  name: 'Home',
  components: {
    FooterNav,
    Tabs
  },
  data() {
    return {
      isAccess: 1,
      isShow: 0,
      showLoading: false,
      game_type_name: {
        1: '网页游戏',
        2: '国内游戏',
        5: '海外游戏'
      },
      // 年月切换
      headpos: 'relative',
      currentIndex: 0,
      time_type: 1,
      time: null,
      tabs: [],
      // 汇总数据
      productActive: 0,
      allDataList: [],
      // 产品列表
      data_type: 1,
      data: [], // 原数据未经过排序
      srotData: [], // 排序数据
      productList: [], // 关注的产品列表
      allProductList: [], // 全部产品列表
      listTabsIndex: null, // 当前列排序序号
      isStatus: null // 当前排序状态
    }
  },
  mounted() {
    // 获取当前切换年月日标签状态
    if (sessionStorage.getItem('currentIndex')) {
      this.currentIndex = parseInt(sessionStorage.getItem('currentIndex'))
      this.time_type = parseInt(sessionStorage.getItem('currentTimeType'))
    }
    console.log(this.time_type)
    //初始化页面
    this.$nextTick(() => {
      this.fnInit()
    })
  },
  methods: {
    /**
     * 初始化
     */
    fnInit() {
      dataInit().then((data) => {
        console.log(data)
        const error = data.error
        if (error === 1000) {
          const res = data.res
          const daylist = [
            {
              name: '本月',
              value: 1
            },
            {
              name: '上月',
              value: 2
            },
            {
              name: '今年',
              value: 3
            },
            {
              name: '去年',
              value: 4
            }
          ]
          // 判断是否本月第1天
          if (parseInt(res.current_day) === 1) {
            daylist.splice(0, 1)
          }
          this.tabs = daylist
          console.log(this.tabs)
          // 判断是否有权限
          if (res.access) {
            this.isAccess = true
            this.fnGetGameOverView()
            this.fnGetOverView()
          } else {
            //TopReels
            if(res.topreelsaccess) {
              this.$router.push({
                path: '/topreels'
              })
              return
            }
            this.isShow = false
            this.isAccess = false
          }
        } else {
          this.isShow = false
          this.isAccess = false
          this.$notify({ type: 'warning', message: data.msg })
        }
      })
    },
    /**
     * 产品概览
     */
    fnGetOverView() {
      const params = {
        time_type: this.time_type //(number,normal) - 1:本月2:上月3:今年4:去年
      }
      dataOverView(params).then((data) => {
        console.log(data)
        const error = data.error
        this.isShow = true
        if (error === 1000) {
          const res = data.res
          this.allDataList = res.list
          this.time = res.start_date + '-' + res.end_date
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 获取产品列表
     */
    fnGetGameOverView() {
      const params = {
        time_type: this.time_type, //(number,normal) - 1:本月2:上月3:今年4:去年
        data_type: this.data_type //(number,normal) - 1:关注的2:全部
      }
      this.isStatus = null
      this.showLoading = true
      dataGameoverView(params).then((data) => {
        console.log(data)
        const error = data.error
        this.isShow = true
        this.showLoading = false
        if (error === 1000) {
          const res = data.res
          let listArray = res.list
          // 判断我的关注是否有关注产品，如果没有切换到全部
          if ((listArray && listArray.length) || this.isInit) {
            if (this.data_type === 1) {
              this.data = [].concat(listArray)
              this.srotData = [].concat(listArray)
              this.productList = [].concat(listArray)
            } else {
              this.allData = [].concat(listArray)
              this.srotAllData = [].concat(listArray)
              this.allProductList = [].concat(listArray)
            }
            // 时间进度
            this.time_progress = res.time_progress
          } else {
            // 假如切换我的关注没有数据，初始化没有数据切到全部产品
            if (this.data_type === 1 && !this.isInit) {
              this.fnProductTabs(1)
            }
          }
          this.isInit = true
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 关注
     */
    fnClickGuanzhu(e) {
      const params = {
        game_id: e.game_id
      }
      console.log(e)
      if (!e.concern) {
        fnGameFocus(
          params,
          () => {
            e.concern = 1
          },
          (msg) => {
            this.$toast.fail(msg)
          }
        )
      } else {
        fnGameUnfocus(
          params,
          () => {
            e.concern = 0
          },
          (msg) => {
            this.$toast.fail(msg)
          }
        )
      }
    },
    /**
     * 年月切换
     */
    fnClickTabs(option) {
      this.active = option.index
      this.time_type = option.item.value
      sessionStorage.setItem('currentTimeType', option.item.value)
      sessionStorage.setItem('currentIndex', option.index)
      this.fnGetGameOverView()
      this.fnGetOverView()
    },
    /**
     * 产品标签切换
     */
    fnProductTabs(e) {
      this.productActive = e
      this.data_type = e + 1
      this.fnGetGameOverView()
    },
    /**
     * 点击排序
     */
    fnClickListTabs(e, key) {
      if (e === this.listTabsIndex) {
        if (this.isStatus === 'up') {
          this.isStatus = 'down'
        } else if (this.isStatus === 'down') {
          this.isStatus = null
        } else {
          this.isStatus = 'up'
        }
      } else {
        this.isStatus = 'up'
      }
      this.listTabsIndex = e
      if (this.isStatus) {
        // 判断是否是我的还是关注排序
        if (this.data_type === 1) {
          this.productList = this.fnSort(this.srotData, key)
        } else {
          this.allProductList = this.fnSort(this.srotAllData, key)
        }
      } else {
        if (this.data_type === 1) {
          this.productList = this.data
        } else {
          this.allProductList = this.allData
        }
      }
      console.log(this.productList, this.data)
    },
    /**
     * 排序
     */
    fnSort(ary, key) {
      console.log(this.listTabsIndex, this.isStatus)
      if (this.isStatus === 'up') {
        return ary.sort(function (a, b) {
          let x = a[key]
          let y = b[key]
          return x < y ? -1 : x > y ? 1 : 0
        })
      } else {
        return ary.sort(function (a, b) {
          let x = a[key]
          let y = b[key]
          return x > y ? -1 : x < y ? 1 : 0
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  .scroll-top {
    height: ((116+48) / @num);
  }
  .scroll-center {
    flex: 1;
    overflow: auto;
  }
  .scroll-footer {
    height: (116 / @num);
  }
}
.head {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: (116 / @num);
  background: #fff;
  padding-left: (20 / @num);
}
.search {
  width: (200 / @num);
}
.search-box {
  width: (144 / @num);
  height: (52 / @num);
  background: #ffffff;
  border-radius: (52 / @num);
  border: (2 / @num) solid #1577ff;
  font-size: @font-28;
  display: flex;
  justify-content: center;
  align-items: center;
  color: @paramcolor;
  span {
    display: block;
    width: (24 / @num);
    height: (24 / @num);
    margin-right: (20 / @num);
    &.icon {
      background: url('../../assets/icon-search@2x.png');
      background-size: 100%;
    }
  }
}
.time {
  display: flex;
  align-items: center;
  height: (48 / @num);
  color: @font-a3a3a3;
  font-size: @font-24;
  margin-left: (32 / @num);
}
.alldata {
  display: flex;
  justify-content: center;
  background: #fff;
  padding: (32 / @num) 0;
  .alldata-item {
    width: (215 / @num);
    height: (188 / @num);
    background: linear-gradient(180deg, #e5f0ff 0%, #f7faff 100%);
    border-radius: (20 / @num);
    margin: 0 (13 / @num);
    padding-left: (20 / @num);
    h2 {
      font-size: @font-28;
      color: @font-7a7a7a;
      font-weight: normal;
      padding-top: (20 / @num);
      padding-bottom: (15 / @num);
    }
    p {
      &.s {
        padding-bottom: (8 / @num);
        font-size: @font-32;
        color: @font-2a2a2a;
        span {
          font-size: @font-24;
          padding-left: (5 / @num);
        }
      }
      &.b {
        font-size: @font-24;
        color: @font-7a7a7a;
        span {
          &.c {
            color: @font-ea5656;
          }
          &.j {
            color: @font-59a252;
          }
          &.n {
            color: @font-7a7a7a;
          }
        }
      }
    }
  }
}
.product-tabs {
  margin-top: (4 / @num);
  height: (84 / @num);
  background: #fff;
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    li {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: @font-30;
      position: relative;
      &.active {
        color: @paramcolor;
        span {
          &.icon1 {
            background: url('../../assets/guanzhu-on@2x.png');
            background-size: 100%;
          }
          &.icon2 {
            background: url('../../assets/quanbuchanpin-on@2x.png');
            background-size: 100%;
          }
        }
        &::after {
          position: absolute;
          content: '';
          bottom: (10 / @num);
          left: (26 / @num);
          right: 0;
          margin: auto;
          width: (64 / @num);
          height: (8 / @num);
          background: @paramcolor;
          border-radius: (4 / @num);
        }
      }
      span {
        position: relative;
        margin-right: (10 / @num);
        &.icon1 {
          width: (24 / @num);
          height: (24 / @num);
          background: url('../../assets/guanzhu@2x.png');
          background-size: 100%;
        }
        &.icon2 {
          width: (28 / @num);
          height: (28 / @num);
          background: url('../../assets/quanbuchanpin@2x.png');
          background-size: 100%;
        }
      }
    }
  }
}
.product-list {
  overflow: hidden;
}
.product-list-no {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: (84 / @num);
  em {
    display: block;
    width: (132 / @num);
    height: (108 / @num);
    background: url('../../assets/zanwushuju@2x.png');
    background-size: 100%;
  }
  p {
    color: @font-a3a3a3;
    font-size: @font-24;
    padding: (24 / @num) 0 (48 / @num);
  }
  a {
    color: @paramcolor;
    font-size: @font-28;
    width: (240 / @num);
    height: (72 / @num);
    line-height: (72 / @num);
    text-align: center;
    background: #ffffff;
    border-radius: (42 / @num);
    border: (2 / @num) solid @paramcolor;
  }
}
.product-list-add {
  display: flex;
  justify-content: center;
  color: @paramcolor;
  height: (72 / @num);
  background: #fff;
  font-size: @font-26;
  align-items: center;
  margin-top: (4 / @num);
  em {
    display: inline-block;
    width: (24 / @num);
    height: (24 / @num);
    background: url('../../assets/jiahao@2x.png');
    background-size: 100%;
    margin-left: (10 / @num);
  }
}
.product-list-box {
  .product-list-box-top,
  .product-list-box-cent-item {
    height: (64 / @num);
    font-size: @font-28;
    display: flex;
    justify-content: flex-end;
    border-bottom: (2 / @num) solid @font-F7F7F7;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: @font-a3a3a3;
      &.t0 {
        padding: 0 (20 / @num);
        em {
          display: block;
          width: (28 / @num);
          height: (28 / @num);
          background: url('../../assets/guanzhu-off@2x.png') no-repeat;
          background-size: 100%;
        }
        &.y {
          em {
            background: url('../../assets/guanzhu-open@2x.png') no-repeat;
            background-size: 100%;
          }
        }
      }
      &.t1 {
        flex: 1;
        justify-content: flex-start;
        padding-left: (32 / @num);
        &.a {
          padding-left: 0;
        }
        a {
          color: @font-a3a3a3;
          display: flex;
          align-items: center;
        }
      }
      &.t2 {
        width: (180 / @num);
      }
      &.t3 {
        width: (140 / @num);
      }
      &.t4 {
        width: (150 / @num);
      }
    }
  }
  .product-list-box-top {
    span {
      em {
        margin-left: (10 / @num);
        width: (12 / @num);
        height: (20 / @num);
        display: inline-block;
        background: url('../../assets/pro-no@2x.png');
        background-size: 100%;
        &.icon1 {
          width: (24 / @num);
          height: (24 / @num);
          background: url('../../assets/bianji@2x.png');
          background-size: 100%;
        }
        &.up {
          background: url('../../assets/pro-up@2x.png');
          background-size: 100%;
        }
        &.down {
          background: url('../../assets/pro-down@2x.png');
          background-size: 100%;
        }
      }
    }
  }
  .product-list-box-cent {
    background: #fff;
    .product-list-box-cent-item {
      position: relative;
      padding: (20 / @num) 0;
      color: @font-2f2f2f;
      height: auto;
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &.a1 {
          left: (68 / @num);
          width: ((750-68) / @num);
        }
      }
      span {
        &.t1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          h2 {
            color: @font-2f2f2f;
            font-size: @font-30;
            font-weight: normal;
          }
          p {
            display: inline-block;
            margin-top: (12 / @num);
            color: @paramcolor;
            padding: 0 (20 / @num);
            height: (40 / @num);
            line-height: (40 / @num);
            font-size: @font-24;
            text-align: center;
            background: linear-gradient(180deg, #e5f0ff 0%, #f7faff 100%);
            border-radius: (8 / @num);
            b {
              font-weight: normal;
            }
          }
        }
        &.t2,
        &.t3,
        &.t4 {
          color: @font-2f2f2f;
          font-size: @font-28;
          &.r {
            color: @font-59a252;
          }
          &.h {
            color: @font-ea5656;
          }
        }
      }
    }
  }
}

.page-no {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  p {
    padding-top: (10 / @num);
    font-size: @font-32;
    color: @font-a3a3a3;
  }
}
</style>
